<template>
    <h3>Enlaces de interes</h3>
    <ul>
      <li><a href="https://www.gob.pe/regionamazonas-drtc" target="_blank" rel="noopener">Portal Gob.Pe</a></li>
      <li><a href="https://recordconductor.mtc.gob.pe/" target="_blank" rel="noopener">Record de Conductor</a></li>
      <li><a href="https://licencias-tramite.mtc.gob.pe/frmConsultaExoneracion.aspx" target="_blank" rel="noopener">Exoneración de Examen</a></li>
      <li><a href="https://licencias-tramite.mtc.gob.pe/#/index" target="_blank" rel="noopener">Licencia de Conducir Electrónica</a></li>
      <li><a href="https://licencias.mtc.gob.pe/#/index" target="_blank" rel="noopener">Portal de Conductor</a></li>
    </ul>
    <p>
        © 1997 - 2023 DIRECCIÓN REGIONAL DE TRANSPORTES Y COMUNICACIONES AMAZONAS - Derechos Reservados
    </p>
</template>

<style>
a {
  color: #42b983;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>